<template lang="html">
  <v-container>
    <v-row fluid>
      <v-col>
        <v-date-field label="Begin Date" v-model="beginDate"></v-date-field>
        <v-date-field label="End Date" v-model="endDate"></v-date-field>
        <v-btn @click="print">Print</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from 'tantee-common/lib/api'
import printDocument from '@/components/mixins/printDocument'

export default {
  mixins: [printDocument],
  data: ()=>({
    beginDate: undefined,
    endDate: undefined,
  }),
  methods: {
    print() {
      let loadingPromise = api.post(['Reporting','ReportDoctorFeeController','getDoctorFee'],{beginDate: this.beginDate, endDate: this.endDate}).then((returnData)=>{
        if (returnData.success) {
          this.printTemporary(undefined,undefined,'report_doctorfee',returnData.data);
        }
      })
      this.$loading('Loading',loadingPromise)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
